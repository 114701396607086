/*
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-11-28 10:30:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: "/",
  //base: "/teach/", //继续教育
  //base: "/study/", //课程  
  routes: [{
    path: '/',
    name: 'index',
    meta: {
      keepAlive: true,
      keycode: "index",
      icon: "mdi-plus",
      iconcolor: "success",
      color: "success",
      textcolor: "success--text text--darken-1",
      text: "指课堂首页",
      readme: "显示我的课程列表，教师可以在页面点击“创建课程”按钮，新增授课课程。",
    },
    component: () => import('@/views/index')
  }, {
    path: '/student',
    name: 'student',
    meta: {
      keepAlive: true,
      keycode: "index",
      icon: "mdi-plus",
      iconcolor: "success",
      color: "success",
      textcolor: "success--text text--darken-1",
      text: "指课堂首页",
      readme: "显示我的课程列表，教师可以在页面点击“创建课程”按钮，新增授课课程。",
    },
    component: () => import('@/views/api/student')
  }, {
    path: '/student-mark',
    name: 'student-mark',
    meta: {
      keepAlive: true,
      keycode: "index",
      icon: "mdi-plus",
      iconcolor: "success",
      color: "success",
      textcolor: "success--text text--darken-1",
      text: "指课堂首页",
      readme: "显示我的课程列表，教师可以在页面点击“创建课程”按钮，新增授课课程。",
    },
    component: () => import('@/views/api/student-mark')
  }]
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router