/*
 * @Author: your name
 * @Date: 2021-07-27 16:01:32
 * @LastEditTime: 2021-12-09 10:46:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@mdi/font/css/materialdesignicons.min.css'
import './plugins/sopen.css'
import vuetify from './plugins/vuetify'
import sopen from './plugins/sopen'
import axios from 'axios';
// 导入全部的规则
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import {
  digits,
  min,
  max,
  numeric,
  min_value,
  max_value,
  email,
  required,
  between,
  integer
} from "vee-validate/dist/rules";


// 2.导入要使用的语言
import zhCN from "vee-validate/dist/locale/zh_CN.json";

localize({
  // 对象成员简易赋值
  zhCN,
});
// 4. 使用具体的语言
localize("zhCN");
extend("digits", digits);
extend("min", min);
extend("max", max);
extend("numeric", numeric);
extend("min_value", min_value);
extend("max_value", max_value);
extend("email", email);
extend("integer", integer);
//extend("required", required);
extend("required", {
  ...required,
  //message: "{_field_}是必选项目",
  message: "必选项目",
});

extend("between", between);


Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.prototype.$sopen = sopen
Vue.prototype.$axios = axios


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


document.title = window.location.href.indexOf("/train/") > -1 ? "罗湖区教师继续教育" : "学校选课平台"