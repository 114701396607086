/*
 * @Author: your name
 * @Date: 2021-06-30 21:21:30
 * @LastEditTime: 2022-02-13 16:30:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/plugins/sopen.js
 */
import axios from 'axios';
//import router from "../router"

import sha1 from 'sha1';

var token = "LuoHuJiaoKeYuan"
var grade = {
	31: "小学一年级",
	32: "小学二年级",
	33: "小学三年级",
	34: "小学四年级",
	35: "小学五年级",
	36: "小学六年级",
	41: "初中一年级",
	42: "初中二年级",
	43: "初中三年级",
	51: "高中一年级",
	52: "高中二年级",
	53: "高中三年级",
};
var subject = {
	1: "语文",
	2: "数学",
	3: "英语",
	4: "物理",
	5: "历史",
	6: "化学",
	7: "生物",
	8: "地理",
	9: "政治",
	10: "体育",
	11: "美术",
	12: "音乐",
	13: "科学",
	14: "信息科技",
	15: "劳动",
	16: "综合实践",
	17: "心理健康",
};


/*
补零
*/
function PrefixZero(num, n) {
	return (Array(n).join(0) + num).slice(-n);
}
/**
 * 
 * @returns 学年时间
 */
function getTerm() {
	var date = new Date();
	var Y = parseInt(date.getFullYear()); //获取完整的年份(4位)
	var m = parseInt(date.getMonth()); //获取当前月份(0-11,0代表1月)
	var d = parseInt(date.getDate()); //获取当前日(1-31)
	return (Y * 10000 + (m + 1) * 100 + d) - (Y * 10000 + 801) >= 0 ? Y : Y - 1;
}





function fun_term(S) {
	var CT = "";
	if (S == 1) {
		CT = "托儿所";
	}
	if (S == 2) {
		CT = "幼儿园";
	}
	if (S == 3) {
		CT = "小学";
	}
	if (S == 4) {
		CT = "初中";
	}
	if (S == 5) {
		CT = "高中";
	}
	if (S == 6) {
		CT = "大学";
	}
	return CT;
}

function fun_classnum(N) {
	switch (N) {
		case 0:
			return "一";
		case 1:
			return "二";
		case 2:
			return "三";
		case 3:
			return "四";
		case 4:
			return "五";
		case 5:
			return "六";
		case 6:
			return "七";
		case 7:
			return "八";
		case 8:
			return "九";
		case 9:
			return "十";
		case 10:
			return "十一";
		case 11:
			return "十二";
	}
}

function GetAge(identityCard) {
	if (!identityCard) return "";
	let len = (identityCard + "").length;
	let strBirthday = "";
	if (len == 18) {
		//处理18位的身份证号码从号码中得到生日和性别代码
		strBirthday =
			identityCard.substr(6, 4) +
			"/" +
			identityCard.substr(10, 2) +
			"/" +
			identityCard.substr(12, 2);
	} else if (len == 15) {
		let birthdayValue = "";
		birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
		if (parseInt(birthdayValue) < 10) {
			strBirthday =
				"20" +
				identityCard.substr(6, 2) +
				"/" +
				identityCard.substr(8, 2) +
				"/" +
				identityCard.substr(10, 2);
		} else {
			strBirthday =
				"19" +
				identityCard.substr(6, 2) +
				"/" +
				identityCard.substr(8, 2) +
				"/" +
				identityCard.substr(10, 2);
		}
	} else {
		return "";
	}
	//时间字符串里，必须是“/”
	let birthDate = new Date(strBirthday);
	let nowDateTime = new Date();
	let age = nowDateTime.getFullYear() - birthDate.getFullYear();
	//再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
	if (
		nowDateTime.getMonth() < birthDate.getMonth() ||
		(nowDateTime.getMonth() == birthDate.getMonth() &&
			nowDateTime.getDate() < birthDate.getDate())
	) {
		age--;
	}
	if (isNaN(age)) {
		return '';
	} else {
		return age;
	}

}

function fun_className(S = '', Y = 0) {
	Y = Y < 1 ? getTerm() : Y;
	S = S.toString()
	var CT = parseInt(S.substring(4, 5));
	switch (CT) {
		case 1:
			CT = "托";
			break;
		case 2:
			CT = "幼";
			break;
		case 3:
			CT = "小";
			break;
		case 4:
			CT = "初";
			break;
		case 5:
			CT = "高";
			break;
		case 6:
			CT = "大";
			break;
	}
	var CY = "";
	if (Y - parseInt(S.substring(0, 4)) >= parseInt(S.substring(5, 6))) {
		CY = S.substring(0, 4) + "届";
	} else {
		CY = CT + this.fun_classnum(Y - parseInt(S.substring(0, 4)));
	}
	var CID = parseInt(S.substring(6, 8));
	if (CID > 0) {
		CID = "（" + PrefixZero(CID, 2) + "）班";
	} else {
		CID = "级";
		//CID = "";
	}
	return CY + CID;
}
/**
 * 关键数据脱敏
 * @param {*} item 
 * @returns 
 */
function shield(item) {
	if (!(item && item.length > 0)) return "-";
	let n = item.length - Math.ceil(item.length / 2);
	let v = "";
	for (let x = 0; x < n; x++) {
		v += "*";
	}
	return item.replace(/^(.{3})(?:\d+)(.{3})$/, "$1" + v + "$2");
}

function shieldName(str) {
	if (null != str && str != undefined) {
		let n = str.length > 3 ? str.length - 2 : str.length - 1;
		let v = "";
		for (let x = 0; x < n; x++) {
			v += "*";
		}
		if (str.length <= 3) {
			return str.substring(0, str.length - n) + v;
		} else {
			return str.substring(0, str.length - n) + v;
		}
	} else {
		return "";
	}
}
function requestLuohu(url){
	return new Promise((resolve) => {
		axios.post(url).then(function (response) {
			console.log(response)
			resolve(response.data);
		}).catch(function (error) {
			console.log(error);
		})
	});
}
function requestApi(data) {
	let learnUrl = "https://us.schoolopen.cn/serve-1.0/api_get_industry.php";
	let n = new Date();
	let string = JSON.stringify(data);
	let time = Math.floor(n.getTime() / 1000);
	let secret = sha1(string + time + token);
	let putData = {
		data: string,
		time: time,
		secret: secret
	}
	return new Promise((resolve) => {
		axios.post(learnUrl, putData).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			console.log(error);
		})
	});
}
export default {
	token,
	grade,
	subject,
	requestLuohu,
	requestApi,
	getTerm,
	PrefixZero,
	fun_term,
	fun_classnum,
	fun_className,
	GetAge,
	shield,
	shieldName,
}