<!--
 * @Author: your name
 * @Date: 2021-07-27 16:01:32
 * @LastEditTime: 2021-12-05 09:55:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /vue-project/src/App.vue
-->
<template>
  <v-app>
    <v-main>
      <v-container class="pa-5"
        ><router-view
          style="margin-top: 10px; margin-bottom: 80px; z-index: 2"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style></style>
